body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@import '~antd/dist/antd.dark.css';*/
/*@import '~antd/dist/antd.compact.css';*/

.site-layout-content {
  min-height: 280px;
}
.logo {
  float: left;
  width: 280px;
  height: 31px;
  margin: 0px 24px 0px 0;
}
.logo img {
  height: 31px;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.ant-layout-header {
  z-index: 100;
}
#background-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  
}
#background-filter {
  background: linear-gradient(to top, #001529, #113454e0);
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
#home-page-content {
  position: relative;
  max-width: 990px;
	margin: 0 auto !important; 
	float: none !important; 
  padding: 25px;
}
#home-page-content .ant-card {
  /*min-height: 140px;*/
  margin-bottom: 25px;
}
#home-page-content .ant-card-meta-detail {
  width: 100%;
}
#home-page-content .ant-card-meta-title {
  font-size: 22px;
  color: #000;
}
#home-page-content .ant-card-meta-description {
  color: #000000ab
}
#home-page-content .ant-card:hover {
  background: #096dd9;
}
#home-page-content .ant-card:hover .ant-card-meta-title {
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7);
  color: #fff;
}
#home-page-content .ant-card:hover .ant-card-meta-description {
  color: rgba(255, 255, 255, 0.7);
}
#home-page-content .ant-card-hoverable, #home-page-content .ant-card-meta-title, #home-page-content .ant-card-meta-description {
  transition: box-shadow 0.3s, border-color 0.3s, background-color 0.3s, color 0.3s;
}

.white-title {
  color: white!important;
}
.white-text {
  color: white!important;
  font-size: larger;
  /*background: #0015299c;*/
  padding: 10px;
}
.layout-home-page {
  padding: 0;
}

.ta-r {
  text-align: 'right';
}
.ta-l {
  text-align: 'left';
}
.ta-c {
  text-align: center;
}
.mr-20 {
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}

.profitpercent-label {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  line-height: 1.1;
}
.profitpercent-center {
  color: white;
  border-radius: 5px;
  background: #002766;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 12px 0;
  display: inline-block;
  text-align: center;
  width: 90px;
}
.profittext-label {
  font-size: 12px;
  display: inline-block;
}

@media screen and (min-width: 640px) {
  .layout-content-page {
    padding: 25px 25px;
  }
}

@media screen and (max-width: 480px) {
.logo {
  width: 180px;
  margin: 0px 0px 0px 24px;
}
.logo img {
  height: 18px;
}
.ant-layout-header {
  padding: 0px;
 min-width: 300px;
}
.profitpercent-label {
  width: 45px;
  line-height: 1.1;
}
}
